//TableSort is a jQuery plugin of table sort
//  Support options
//    remote:        use remote server for sorting, could be true/false or success ajax callback function
//    blockingElement:  element that get blocked while sorting remotly, default is table element itself
//    typeFunc:      array contain functions that can convert value of sort by to type, for get the sort funciton
//    sortFunc:      hash { type: sort_function }
//
//  Specify attributes in HTML tag:
//    sorting: <th data-sorting='false'>only<th>, do not sort this th
//    sortingDirection: <th data-sorting-direction='asc'>Direction<ht>, Only sort by ASC
//    rawValue: <td data-raw-value='this will be the value to sort'></td>
//    sortType: <th data-sorting-type='int'><th>, use the 'int' sort function
//    defaultSort: <th data-sorting-default="asc"> will sort this th with asc order at the beginning
//
//  Usage:
//    jQuery(tableSelector).tableSort(options)
//
//  Known issue: this does't work well with floatThead. need call tableSort() before floatThead();

(function($) {
  function TableSort(table, options) {
    this.table = table;
    this.options = options;

    this.sortFunc = options.sortFunc;
    this.typeFunc = options.typeFunc;
    this.dir = options.dir;
    this.sortDir = 'sorting-dir';
    this.sortType = 'sorting-type';
    this.rawValue = 'raw-value';
    this.ascClass = 'sorting-' + options.dir.ASC;
    this.descClass = 'sorting-' + options.dir.DESC;
    this.remote = options.remote;
    this.blockingElement = options.blockingElement || this.table;

    this.selectedThs = this.table.children('thead').find("th:not([data-sorting=false])");

    this.thIndex = this.calcThIndex(this.table);
  }

  TableSort.prototype = {
    constructor: TableSort,

    listen: function() {
      var self = this;
      var $ths = this.selectedThs;

      TableSort.AddThStyle($ths);
      $ths.on('click.tableSort', function() { self.sort(this); });

      this.sortDefaultTh($ths);
    },

    sortDefaultTh: function($ths) {
      var $defaultTh = $($ths.filter('[data-sorting-default]').first());

      if ($defaultTh.length) {
        var value = $defaultTh.data('sorting-default');
        if (value == this.dir.ASC || value == this.dir.DESC) {
          this.defaultDir = value;
        } else {
          text = ["defaultTh incorrect. selector: ", selector, ', data key: ', 'sorting-default', ' data value: ', value].join('');
          console.log(text);
        }

        this.sort($defaultTh);
      }
    },

    unListen: function() {
      var $ths = this.selectedThs;

      TableSort.removeThStyle($ths);
      $ths.off('click.tableSort');
      this.table = null;
      this.selectedThs = null;
      this.blockingElement = null;
    },

    //calculate the th index, each th map a td index which should be sort while th be clicked
    //ouput: { "tr_num,th_num": td_index }
    calcThIndex: function(table) {
      var thIndex = {};
      var rowPlaceholders = {};
      var colPlaceholders = {};
      table.children('thead').find('tr').each(function(trNum, e) {
        var col = 0;
        $(this).find('th').each(function(thNum, e) {
          var $th = $(this);
          var rows = parseInt(($th.attr('rowspan') || 1), 10);
          var cols = parseInt(($th.attr('colspan') || 1), 10);

          //move the col caused by rowspans & colspan
          while (rowPlaceholders[col] > 0) {
            if (colPlaceholders[col] > 0) {
              col += colPlaceholders[col];
            }
            --rowPlaceholders[col];
            ++col;
          }

          if (rows > 1) {
            rowPlaceholders[col] = rows - 1;
            colPlaceholders[col] = cols - 1;
          }

          thIndex[trNum + ',' + thNum] = col;
          col += cols
        });
      });

      return thIndex;
    },

    sort: function(th) {
      var $th = $(th);

      var direction = $th.data('sorting-direction');
      var index = this.thIndex[$th.parent('tr').index() + ',' + $th.index()];
      var sortDir = null;
      if (direction) {
        if ($th.data(this.sortDir) == direction) return;
        sortDir = direction == this.dir.DESC ? this.dir.DESC : this.dir.ASC;
      } else {
        if ($th.data(this.sortDir)) {
          sortDir = $th.data(this.sortDir) === this.dir.ASC ? this.dir.DESC : this.dir.ASC;
        } else {
          sortDir = this.defaultDir || this.dir.ASC
        }
      }

      //trigger beforetablesort event
      this.table.trigger("beforetablesort", {column: index, direction: sortDir});
      this.table.css("display");

      var self = this;
      setTimeout(function() {
        if (self.remote) {
          self.sortRemotly($th, index, sortDir);
        } else {
          //sort tbody one by one
          self.table.children('tbody').each(function() {
            self.sortLocaly($(this), $th, index, sortDir);
          });

          //update css class & store sort direction on jQuery data
          $th.parents('table').find('th').data(self.sortDir, null).removeClass(self.ascClass + " " + self.descClass);
          $th.data(self.sortDir, sortDir).addClass('sorting-' + sortDir);

          //trigger aftertablesort event
          self.table.trigger("aftertablesort", {column: index, direction: sortDir});
          self.table.css("display");
        }

      }, 10);
    },

    sortRemotly: function($th, index, sortDir) {
      if( this.isRemoteSorting) { return; }

      var spinner = new Spinner({
        color: '#fff'
      }).spin();
      var $blockingElement = $(this.blockingElement);
      $('body').append(spinner.el);
      $blockingElement.block({
        message: $(spinner.el),
        css: {
          border: 0
        },
        overlayCSS: {
          zIndex: 1010
        }
      });
      var data = { sorting: {}}, name = $th.attr('name') || $th.text();
      data.sorting[name] = sortDir;
      var self = this;
      var ajax = typeof awfAjax == 'undefined' ? $.ajax : awfAjax;
      var $table = this.table;

      this.isRemoteSorting = true;

      var xhr = ajax({
        method: 'get',
        url: this.table.data('sorting-url'),
        data: data
        //dataType: 'text'
      });

      if(typeof this.options.remote == 'function') {
        xhr.done(function(data) {
          self.options.remote($table, data);
        });
      }
      xhr.always(function() {
        self.isRemoteSorting = false;
        $blockingElement.unblock();
        spinner.stop();
      });
    },

    sortLocaly: function ($tbody, $th, index, sortDir) {
      var $trs = $tbody.children('tr:not([data-sorting=false])');
      var self = this;
      var map = null;

      //get arr that contained value of order_by
      var arr = self.getTdValues($trs, index);

      var typeOfSortFunc = $th.data(self.sortType) || self.getType(arr);
      var sortFunc = self.sortFunc[typeOfSortFunc];

      //get map that contained the order of sorted tr
      if (sortDir == self.dir.ASC) {
        map = self.sortMap(arr, function(a, b){ return sortFunc(a, b, sortDir) });
      } else {
        map = self.sortMap(arr, function(a, b){ return -sortFunc(a, b, sortDir) });
      }

      //replace sortedTrs
      $sortedTrs = $(self.applySortMap($trs, map));
      $trs.detach();
      $tbody.append($sortedTrs);
    },

    getTdValues: function($trs, index) {
      var arr = [];
      var self = this;

      $trs.each(function() {
        var $tr = $(this);
        var tdIndex = 0;
        var $td = null;
        $tr.children('td').each(function() {
          //get current td while match
          if (tdIndex == index) {
            $td = $(this);
            return false;
          }

          //we get prev td cause we know the td we need was eaten by prev td with colspan
          if (tdIndex > index) {
            $td = $(this).prev();
            return false;
          }

          tdIndex += parseInt(($(this).attr('colspan') || 1), 10);
        })


        if ($td == null) { $td = $tr.find('td:last') }

        var order_by = $td.data(self.rawValue);
        if (order_by == undefined || order_by == null) { order_by = $td.text(); }
        arr.push($.trim(order_by));
      });

      return arr;
    },

    getType: function(valueArr) {
      func = this.typeFunc;
      var returnVaule = null;

      for(var i = 0; i < func.length; i++) {
        returnVaule = func[i].call(this, valueArr[0]);
        if (returnVaule) {
          break;
        }
      }

      return returnVaule;
    },

    sortMap: function(arr, sort_function) {
      var map = [];
      var index = 0;
      var sorted = arr.slice(0).sort(sort_function);

      for (var i=0; i<arr.length; i++) {
        index = $.inArray(arr[i], sorted);

        // If this index is already in the map, look for the next index.
        // This handles the case of duplicate entries.
        while ($.inArray(index, map) != -1) {
          index++;
        }
        map.push(index);
      }

      return map;
    },

    applySortMap: function(arr, map) {
      var clone = arr.slice(0),
      newIndex = 0;
      for (var i=0; i<map.length; i++) {
        newIndex = map[i];
        clone[newIndex] = arr[i];
      }
      return clone;
    }
  };

  //define default options
  TableSort.defaultOptions = {
    remote: false,                   //sorting in remote server
    blockingElement: null,
    dir: { ASC: 'asc', DESC: 'desc' },
    typeFunc: [
      function (data) { if (/^[+-]?\d{1,3}(,\d{3})+(\.\d+)?$/.test(data)) { return 'numeric-comma'; } },
      function (data) { if (/^[+-]?\d+$/.test(data)) { return 'int'; } },
      function (data) { if (/^[+-]?\d+\.\d+$/.test(data)) { return 'float'; } },
      function (data) { return 'string' }
    ],
    sortFunc: {
      'numeric-comma': function(a, b) {
        var x = parseFloat(a.replace(/,/g, ""));
        var y = parseFloat(b.replace(/,/g, ""));
        x = isNaN(x) ? 0 : x
        y = isNaN(y) ? 0 : y

        return x - y;
      },
      'numeric-only': function(a, b) {
        var x = parseFloat(a.replace(/^.*?([-+]?[\d]*\.?[\d]+(?:[eE][-+]?[\d]+)?).*$/,"$1"));
        var y = parseFloat(b.replace(/^.*?([-+]?[\d]*\.?[\d]+(?:[eE][-+]?[\d]+)?).*$/,"$1"));
        x = isNaN(x) ? 0 : x
        y = isNaN(y) ? 0 : y

        return x - y;
      },
      "int": function(a, b) {
        return parseInt(a, 10) - parseInt(b, 10);
      },
      "float": function(a, b) {
        return parseFloat(a) - parseFloat(b);
      },
      "string": function(a, b) {
        if (a < b) return -1;
        if (a > b) return +1;
        return 0;
      },
      "string-ins": function(a, b) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        if (a < b) return -1;
        if (a > b) return +1;
        return 0;
      }
    }
  }

  //Added Th class style, used for TableSort#listen()
  TableSort.AddThStyle = function($ths) {
    $ths.addClass('sorting');

    $ths.each(function() {
      var div = $('<div class="sorting-arrows"></div>');
      $(this).append(div);
    });
  }

  //Removed Th class style, used for TableSort.#unListen();
  TableSort.removeThStyle = function($ths) {
    $ths.removeClass('sorting');
    $ths.find('div.sorting-arrows').remove();
  }

  //make TableSort available in top level
  window.TableSort = TableSort;

  //register tablesort function
  $.fn.tableSort = function (options) {
    return this.each(function() {
      options = options || {};
      options = $.extend(true, {}, TableSort.defaultOptions, options);
      var $table = $(this);
      var dataKey = '__tableSort__';

      if (options.destroy) {
        //destroy tableSort
        $table.data(dataKey) && $table.data(dataKey).unListen();
        $table.data(dataKey, null)
      } else {
        //register th click event, beautiful table sort arrows
        var tableSort = new TableSort($table, options);
        tableSort.listen();

        //store TableSort instance in table
        $table.data(dataKey, tableSort);
      }
    })
  }
})(jQuery)

