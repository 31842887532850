import './js_error_notification.js'
import moment from 'moment';

window.ID = function() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

// http://web.archive.org/web/20190703112119/http://www.somesolvedproblems.com/2018/07/how-do-i-make-plotly-faster.html
window.drawChart = function(chart_id, datas, layout, options, method) {
  var _layout = Object.assign({
    height: 300,
    margin: {
      l: 50,
      r: 10,
      t: 40,
      b: 30,
      pad: 0
    },
    xaxis: {rangemode: 'nonnegative', tickfont: {size: 10}},
    yaxis: {rangemode: 'nonnegative', tickfont: {size: 10}}
  }, layout)
  var _options = Object.assign({responsive: true}, options)

  // https://plotly.com/javascript/plotlyjs-function-reference/
  if(method === 'newPlot'){
    Plotly.newPlot(chart_id, datas, _layout, _options)
  } else if(method === 'relayout') {
    Plotly.relayout(chart_id, _layout)
  } else {
    Plotly.react(chart_id, datas, _layout, _options)
  }
}

window.formatLength = function(x) {
  return x.toFixed(3) + "mm" // The toFixed() method converts a number into a string, rounding to a specified number of decimals.
}

window.formatMeterLength = function(x) {
  return formatLength(x * 1000)
}

window.formatDuration = function(x) {
  let hours = Math.floor((x / 3600))
  let minutes = Math.floor((x / 60 % 60))
  let seconds = Math.floor((x % 60))

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}

window.formatTimestamp = function(x) {
  return moment(x).format('D MMM, HH:mm:ss')
}