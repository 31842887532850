import { fabric } from "fabric";

function setStrokeStyle(ctx, traj){
  if(traj.optics_id === 2){
    ctx.strokeStyle = 'yellow'
  } else {
    ctx.strokeStyle = 'green'
  }
}

function stroke_paramaters(scanning_rule, stroke_width_type){
  if(!scanning_rule){
    return {
      kclass: 'no_scanning_rule_stroke',
      stroke_width: 1,
      radius: 1
    }
  }
  var beam_diameter = Math.abs(scanning_rule['D033'])
  var stroke_width = 1
  if (stroke_width_type === 'diameter') {
    return {
      stroke_width: beam_diameter,
      radius:  stroke_width * 2,
      klass: 'diameter_stroke'
    }
  } else if (stroke_width_type === 'melt_pool') {
    return {
      stroke_width: beam_diameter * 2.0,
      radius: stroke_width * 2,
      klass: 'melt_pool_stroke'
    }
  } else {
    return {
      stroke_width: beam_diameter * 0.1,
      radius: 1,
      klass: 'thin_line_stroke'
    }
  }
}

function format_coordinate(x) {
  return (x * 1000).toFixed(3) + "mm"
}

function render_trajectories(options){
  const {
    ctx, trajectories, stroke_width_type,
    current_page, data, fabric_canvas, fabric_group, transform_matrix
  } = options
  // console.log(trajectories[0])
  trajectories.forEach((traj, traj_index) => {
    layerTrajectories[data.svg_json.layer_index][traj.index] = []
    var fabric_ponts = traj.points.map( p =>{
      return {x: p[0], y: -p[1]} // minus y, cause img reverse when zoom
      // return fabric.util.transformPoint({
      //     x: p[0],
      //     y: p[1]
      //   }, transform_matrix
      // )
    })

    // console.log(fabric_ponts)
    var geometry_code = traj.geometry_code
    var scanning_rule = data.svg_json.scanning_rules[traj.scanning_rule_index]
    let {klass, stroke_width, radius} = stroke_paramaters(scanning_rule, stroke_width_type)
    // stroke_width = stroke_width * transform_matrix[0]
    const default_options = {
      // originX: 'center',
      // originY: 'center',
      // selectable: false,
      // left: fabric_ponts[0].x,
      // top: fabric_ponts[0].y
    }
    // if (traj_index === 0) console.log({ klass, geometry_code, stroke_width })
    setStrokeStyle(ctx, traj)
    if (geometry_code === 0) { // polyline
      var polyline = new fabric.Polyline(
        // traj.points.map(p => ({ x: p[0], y: p[1]}) ),
        fabric_ponts,
        {
          ...default_options,
          stroke: ctx.strokeStyle,
          strokeWidth: stroke_width,
          fill: 'transparent',
        }
      )
      layerTrajectories[data.svg_json.layer_index][traj.index].push({obj: polyline, optics_id: traj.optics_id})
      // fabric_group.addWithUpdate(polyline)
      fabric_group.add(polyline)
      // fabric_canvas.add(polyline)
    } else if (geometry_code === 1) { // circle
      ctx.fillStyle = '#00f'
      fabric_ponts.forEach( p => {
        // path.arc(p[0], p[1], radius, 0, 2 * Math.PI)
        var circle = new fabric.Circle({
          ...default_options,
          radius: radius, fill: '#00f',
          left: p.x, top: p.y,
          strokeWidth: stroke_width,
          stroke: ctx.strokeStyle,
          fillStyle: '#00f',
        });

        layerTrajectories[data.svg_json.layer_index][traj.index].push({obj: circle, optics_id: traj.optics_id})
        fabric_group.add(circle);
        // fabric_canvas.add(circle)
      })

    } else if (geometry_code === 2) {
      var i, j, temparray, chunk = 2;
      var path_d
      for (i = 0, j = fabric_ponts.length; i < j; i += chunk) {
        temparray = fabric_ponts.slice(i, i + chunk);
        var pt1 = temparray[0]
        var pt2 = temparray[1]
        if (pt2) {
          if(path_d){
            path_d += ` M${pt1.x} ${pt1.y} L${pt2.x} ${pt2.y}`
          } else {
            path_d = `M${pt1.x} ${pt1.y} L${pt2.x} ${pt2.y}`
          }
        } else {
          console.warn(`Warning: ignoring pt1 ${pt1} because missing pt2`)
        }
      }
      var path = new fabric.Path(path_d)
      // console.log(path_d)
      path.set({
        ...default_options,
        stroke: ctx.strokeStyle,
        strokeWidth: stroke_width,
        fill: 'none',
      })
      layerTrajectories[data.svg_json.layer_index][traj.index].push({obj: path, optics_id: traj.optics_id})
      fabric_group.add(path)
    } else {
      throw `Unknown geometry_code: ${geometry_code}`
    }
  })
}

function calc_timeout(p1, p2, traj){
  // return 20
  let distance = 0.0
  if(p1 && p2) {
    distance = Math.abs(Math.pow(p1[0] - p2[0], 2) - Math.pow(p1[1] - p2[1], 2))
  } else {
    return 0
  }
  // return distance / speed

  var speed_down_factor = 20 / drawingConfig.speed_factor
  var proportion = distance / traj.marked_length
  var expected_time = traj.expected_scan_time || 50

  // console.log({expected_time, proportion})
  var timeout
  if(proportion >  1/10) {
    timeout = expected_time * speed_down_factor
  } else if(proportion > 1/20) {
    timeout = expected_time * 0.8 * speed_down_factor
  } else if(proportion > 1/50) {
    timeout = expected_time * 0.6 * speed_down_factor
  } else if(proportion > 1/100) {
    timeout = expected_time * 0.4 * speed_down_factor
  } else {
    timeout = expected_time * 0.2 * speed_down_factor
  }

  // if(timeout < 5) {
  //   return 5
  // }
  return timeout
}

function start_drawing_thread(options) {
  const {trajectory_batches, ...drawing_options} = options
  let batch_indexes = {1: 0, 2: 0}
  const check_queue = (optics_id) => {
    // console.log(`check_queue batch_index: ${batch_index}  ${trajectory_batches[batch_index]?.length}`)
    const current_index = batch_indexes[optics_id]
    let current_batch = trajectory_batches[current_index]
    if(current_batch?.length > 0){
      var trajectories = current_batch.filter(x => x.optics_id === optics_id)
      if(current_index % 50 === 0){
        console.log(`optics_${optics_id}: batche(${current_index}) size: ${current_batch.length}, trajectories: ${trajectories.length}`) 
      }
      if(trajectories.length > 0) {
        draw_trajectories({
          ...drawing_options,
          trajectories: trajectories,
          draw_next_batch: () => {
            // console.log("draw next") 
            batch_indexes[optics_id] += 1
            check_queue(optics_id)
          }
        })
      } else {
        // Fix recipe M203372-003_PRNT_v3
        batch_indexes[optics_id] += 1
        setTimeout(() => check_queue(optics_id), 50)
        // console.log(`optics_${optics_id} done ...`)
      }
      // })
    } else {
      if(current_batch?.length === 0){
        console.log(`optics_${optics_id} done ...`)
      } else {
        setTimeout(() => check_queue(optics_id), 50)
      }
    }
  }
  check_queue(1)
  check_queue(2)
}

const drawingConfig = {
  pause_drawing: false,
  speed_factor: 1
}
function draw_trajectories(options) {
  const {
    ctx, trajectories, stroke_width_type,
    data, transform_matrix,
    viewBox, draw_next_batch
  } = options
  // const speed_times = [0.1, 0.2, 0.5, 1, 2, 5, 10]
  // console.log(trajectories[0])

  const draw_trajectory = (trajs_index) => {
    var traj = trajectories[trajs_index]
    var geometry_code = traj.geometry_code

    var scanning_rule = data.svg_json.scanning_rules[traj.scanning_rule_index]
    var beam_diameter = scanning_rule['D033']
    // var scan_speed = (scanning_rule['D034'] || 1.0) * 0.00000001
    // console.log({scan_speed})
    const {klass, stroke_width, radius} = stroke_paramaters(scanning_rule, stroke_width_type)

    // var interval = 20
    const draw_path = (index, next_path_callback) => {
      setStrokeStyle(ctx, traj)
      var point = traj.points[index]
      // console.log({point})
      if (!point) {
        // console.log(traj_index, trajectories.length)
        if (index >= traj.points.length - 1) {
          // next_traj_callback()
          if(trajectories[trajs_index +1]){
            draw_trajectory(trajs_index + 1)
          } else {
            draw_next_batch()
          }
        }
        return
      }

      if (geometry_code === 0) { // polyline
        ctx.beginPath()
        ctx.lineWidth = stroke_width
        var continue_index = index === 0 ? 0 : index - 1
        ctx.moveTo(...traj.points[continue_index])
        ctx.lineTo(...point)
        // ctx.closePath()
        ctx.stroke()
      } else if (geometry_code === 1) { // circle
        ctx.beginPath();
        ctx.lineWidth = stroke_width
        ctx.fillStyle = '#00f'

        ctx.arc(x, y, radius, 0, 2 * Math.PI)
        // ctx.closePath()
        ctx.stroke()
      } else if (geometry_code === 2) {
        ctx.beginPath()
        ctx.lineWidth = stroke_width

        var pt1 = traj.points[index]
        var pt2 = traj.points[index + 1]
        // console.log({pt1, pt2})
        if (pt2) {
          ctx.moveTo(...pt1)
          ctx.lineTo(...pt2)
          // ctx.closePath()
          ctx.stroke()
        } else {
          console.warn(`Warning: ignoring pt1 ${pt1} because missing pt2`)
        }
      } else {
        throw `Unknown geometry_code: ${geometry_code}`
      }

      let animate_timeout = 0
      let previous_timeout = 0
      let switch_time = 0
      if (geometry_code === 2) {
        animate_timeout = calc_timeout(traj.points[index], traj.points[index + 1], traj) + switch_time
      } else {
        animate_timeout = calc_timeout(traj.points[index - 1], traj.points[index], traj) + switch_time
      }

      const check_pausing_or_draw = () => {
        if(drawingConfig.pause_drawing) {
          setTimeout(() => {
            check_pausing_or_draw()
          }, 1000)
          console.log('pausing ....')
          return
        }

        if(next_path_callback) {
          next_path_callback()
        } else {
          if (geometry_code === 2) {
            setTimeout(() => {
              index = index + 2
              draw_path(index, () => draw_path(index + 2) )
            }, animate_timeout)
          } else {
            setTimeout(() => {
              index = index + 1
              draw_path(index, () => draw_path(index + 1) )
            }, animate_timeout)
          }
        }
      }

      check_pausing_or_draw()

    }

    draw_path(0)
  }

  var trajs_index = 0
  draw_trajectory(trajs_index)
}


function draw_canvas(data){
  drawingConfig['data'] = data

  var svg_json = data.svg_json;
  var has_next_page = true
  var page_index = 1
  // var timeout_accumulators = {1: 0, 2: 0} // two optics
  const canvas = document.createElement('canvas')
  const $container = $(data.el_id)
  canvas.style.background = 'black'
  canvas.style.filter = 'brightness(3)'
  $container.append(canvas)
  const ctx = canvas.getContext('2d');

  // https://www.html5rocks.com/en/tutorials/canvas/hidpi/
  var dpr = window.devicePixelRatio || 1;
  canvas.width = $container[0].clientWidth
  canvas.height = $container[0].clientHeight
  
  // var rect = canvas.getBoundingClientRect();
  // canvas.width = rect.width * dpr;
  // canvas.height = rect.height * dpr;
  // console.log({dpr})
  // ctx.scale(dpr, dpr);

  // var transform_matrix = data.svg_json.transform_matrix.split(' ').map(x => parseFloat(x))
  var viewBox = data.svg_json.viewBox.split(' ').map(x => parseFloat(x))

  const canvasMinEdge = canvas.height > canvas.width ? canvas.width : canvas.height
  let scaleX = canvasMinEdge / viewBox[3]

  var translateX = canvas.width * 0.5
  var translateY = canvas.height * 0.5
  ctx.lineWidth = 1.0 / scaleX
  ctx.fillStyle = "#00f";
  ctx.strokeStyle = 'green';
  const transform_matrix = [scaleX, 0, 0, -scaleX, translateX, translateY]

  ctx.setTransform(...transform_matrix)
  // ctx.transform(...transform_matrix)

  const trajectory_batches = []

 
  const fetchPagesLoop = async _ => {
    while (has_next_page && page_index <= svg_json.page_count) {
      // console.log(`page_count ${svg_json.page_count}`)
      var next_page_paths = []
      var batch_size = 5

      for (var i = 0; page_index <= svg_json.page_count && i < batch_size; i++, page_index++) {
        next_page_paths.push(data.base_url + data.trajectories_data_path + "&page=" + page_index)
        has_next_page = page_index < svg_json.page_count
      }

      let responses = await Promise.all(next_page_paths.map(x => fetch(x).then(resp => resp.json())))
      
      responses.forEach(function (response_json) {
        var trajectories = response_json.trajectories;
        // var current_page = response_json.current_page;
        trajectory_batches.push(trajectories)
        // if (trajectories && trajectories.length > 0) {
        //   svg_json.stroke_width_styles.forEach(stroke_width_type => {
        //     draw_trajectories({
        //       ctx, trajectories, stroke_width_type, current_page,
        //       data, transform_matrix, viewBox, timeout_accumulators
        //     })
        //   })
        // } else
        if (trajectories && trajectories.length === 0) {
          has_next_page = false
        }
      })

    }
    trajectory_batches.push([])
  }

  svg_json.stroke_width_styles.forEach(stroke_width_type => {
    start_drawing_thread({
      ctx, stroke_width_type,
      data, transform_matrix, trajectory_batches
    })
  })
  fetchPagesLoop()

}


function show_spinner(fabric_canvas, options) {
  // const fabric_group = new fabric.Group(
  //   [],
  //   { 
  //     selectable: false, interactive: false,
  //     left: 0, top: 0, originY: 'center', originX: 'center',
  //   }
  // )
  const spinner = new fabric.Circle({
    left: 0, top: 0,
    angle: 0, startAngle: 0, endAngle: 300,
    stroke: 'white', selectable: false,
    originX: 'center', originY: 'center', fill: '',
    ...options
  });
  // var text = new fabric.Text('Loading ...', { left: 0, top: 0 });
  // fabric_group.add(spinner);
  // fabric_group.addWithUpdate(text)
  // fabric_canvas.add(fabric_group);
  // fabric_canvas.viewportCenterObject(fabric_group)
  fabric_canvas.add(spinner)
  fabric_canvas.renderAll()
  const spinner_animate = () => {
    // setTimeout(spinner_animate, 210)
    spinner.animate('angle', '+=60', {
      duration: 100,
      onChange: fabric_canvas.renderAll.bind(fabric_canvas),
      onComplete: ()=> {
        // console.log('animate complete ...')
        spinner_animate();
      }
    });
  }

  spinner_animate()
  return spinner
}

const layerTrajectories = {} // for highlight trajectories
const layerCanvases = {}

// fabric.Object.prototype.objectCaching = false;
// fabric.Object.prototype.transparentCorners = false;
// fabric.Object.prototype.enableRetinaScaling = false;
fabric.Object.prototype.originX = fabric.Object.prototype.originY = 'center';

fabric.Canvas.prototype.getAbsoluteCoords = function(object) {
  return {
    left: object.left + this._offset.left,
    top: object.top + this._offset.top
  };
}
function render_canvas(data) {
  // console.debug(data)
  let {
    selectable = true, clearPrevious,
    hoverCursor, hoverShowCoordinates = true
  } = data
  // selectable = false
  if(clearPrevious){
    [layerTrajectories, layerCanvases].forEach(obj => {
      Object.keys(obj).forEach(key => delete obj.key )
    })
  }

  layerTrajectories[data.svg_json.layer_index] = {}
  var svg_json = data.svg_json;
  var has_next_page = true
  var page_index = 1
  const canvas = document.createElement('canvas')
  canvas.style.filter = 'brightness(2)'
  const $container = $(data.el_id)
  $container.css('cursor', 'wait')
  
  if(clearPrevious){ $container.html('') }
  $container.append(canvas)
  const ctx = canvas.getContext('2d');
  var fabric_canvas = new fabric.Canvas(canvas);

  // fabric_canvas.enableRetinaScaling = false;

  if($container.width() > 0){
    // $container.css('min-height', `${$container.width()}px`)
    fabric_canvas.setHeight($container.height())
    fabric_canvas.setWidth($container.width())
  } else {
    console.warn(`canvas container width is ${$container.width()}`)
  }

  layerCanvases[data.svg_json.layer_index] = fabric_canvas
  // var transform_matrix = data.svg_json.transform_matrix.split(' ').map(x => parseFloat(x))
  var viewBox = data.svg_json.viewBox.split(' ').map(x => parseFloat(x))

  const canvasMinEdge = fabric_canvas.height > fabric_canvas.width ? fabric_canvas.width : fabric_canvas.height
  let scaleX = canvasMinEdge / viewBox[3]
  var translateX = fabric_canvas.width * 0.5
  var translateY = fabric_canvas.height * 0.5

  ctx.fillStyle = "#00f";
  ctx.strokeStyle = 'green';
  fabric_canvas.backgroundColor = 'black'
  const transform_matrix = [scaleX, 0, 0, scaleX, translateX, translateY]
  // const transform_matrix = [scaleX * zoom, 0, 0, -scaleX * zoom, 0, 0]
  fabric_canvas.setViewportTransform(transform_matrix)
  
  const spinner = show_spinner(
    fabric_canvas,
    {
      radius: 30 / scaleX,
      strokeWidth: 5 / scaleX,
      strokeStyle: 'green'
    }
  )

  var fabric_group = new fabric.Group(
    [], {
      selectable: false, // don't allow select until render is done
      interactive: false,
      hoverCursor: 'wait'
  })
  fabric_canvas.add(fabric_group)

  let render_finished = false
  const after_render = () => {
    fabric.runningAnimations.cancelAll()
    fabric_canvas.remove(spinner)
    fabric_group.set({
      selectable: selectable,
      hoverCursor: hoverCursor || selectable ? 'point' : 'zoom-in',
    })
    fabric_canvas.set({interactive: selectable})
    $container.css('cursor',  '')
    fabric_group.addWithUpdate()

    fabric_canvas.viewportCenterObject(fabric_group)
    fabric_canvas.renderAll()
    render_finished = true
  }

  if(hoverShowCoordinates){
    fabric_canvas.on('mouse:move', (options) =>{
      let p = options.pointer
      // calculate the total transformation that is applied to the objects pixels:
      const mCanvas = fabric_canvas.viewportTransform
      const mGroup = fabric_group.calcTransformMatrix()
      const mTotal = fabric.util.multiplyTransformMatrices(mCanvas, mGroup);
      const mTotalInverse = fabric.util.invertTransform(mTotal);
      // console.log({mGroup})
      p = fabric.util.transformPoint(p, mTotalInverse);
      // console.log({mCanvas, mTotal, mTotalInverse, p})

      // Y coordinates is reversed manually not by transform_matrix
      p.y = -p.y
  
      let $text = $('#canvas_hover_text')
      if ($text.length === 0) {
        $('body').append('<small id="canvas_hover_text"/>')
        $text = $('#canvas_hover_text')
      }
  
      $text.css({
        top: options.e.clientY + 10,
        left: options.e.clientX + 10,
        position: 'absolute',
        zIndex: 1000,
      })
  
      $text.html(`Current Coordinates: <br/> (${format_coordinate(p.x)}, ${format_coordinate(p.y)})`)
      $text.show()
    });
    fabric_canvas.on('mouse:out', () => {
      $('#canvas_hover_text').hide()
    })
  }

  const handle_resize = ()=> {
    var canvasScaleFactor = $container.width() / fabric_canvas.width;
    var width = $container.width()
    var height = $container.height()
    var ratio = fabric_canvas.height / fabric_canvas.width;
    const centerPoint = fabric.util.transformPoint({x: 0, y: 0}, fabric_canvas.viewportTransform)

    // if((width / height) > ratio){
    //   width = height * ratio;
    // } else {
    //   height = width / ratio;
    // }
    var scale = width / fabric_canvas.width;
    var zoom = fabric_canvas.getZoom();
    zoom *= scale;
    fabric_canvas.setDimensions({ width: width, height: height });
    // fabric_canvas.zoomToPoint(centerPoint, zoom);
    fabric_canvas.setZoom(zoom);
    fabric_canvas.viewportCenterObject(fabric_group)
    fabric_canvas.renderAll()
  }
  window.addEventListener('resize', handle_resize, false);

  const defaultZoom = fabric_canvas.getZoom()
  const maxZoom = 10 * defaultZoom
  const minZoom = defaultZoom / 5
  if(selectable){
    fabric_canvas.on('mouse:wheel', function(opt) {
      if(!render_finished) return

      var delta = opt.e.deltaY;
      var zoom = fabric_canvas.getZoom();
      // console.log({zoom: zoom, delta: delta})
      zoom *= 0.999 ** delta;
      if (zoom > maxZoom) zoom = maxZoom;
      if (zoom < minZoom) zoom = minZoom;

      fabric_canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      // fabric_canvas.setZoom(zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    })
  }

  const fetchPagesLoop = async _ => {
    let rendered_page_count = 0
    const { page_count } = svg_json
    while (page_index <= page_count) {
      var next_page_paths = []
      var batch_size = 2
  
      for (var i = 0; page_index <= page_count && i < batch_size; i++, page_index++) {
        next_page_paths.push(data.base_url + data.trajectories_data_path + "&page=" + page_index)
        has_next_page = page_index < page_count
      }
  
      Promise.all(next_page_paths.map(x => fetch(x).then(resp => resp.json()))).then( responses => {
        responses.forEach(function (response_json) {
          var trajectories = response_json.trajectories;
          var current_page = response_json.current_page;
    
          if (trajectories && trajectories.length > 0) {
            svg_json.stroke_width_styles.forEach(stroke_width_type => {
              render_trajectories({
                ctx, trajectories, stroke_width_type,
                current_page, data, fabric_canvas, fabric_group,transform_matrix
              })
            })
            rendered_page_count += 1
          }
          if (rendered_page_count >= page_count) {
            after_render()
          }
        })
      })
    }
  }
  fetchPagesLoop()

  // console.log({layerTrajectories, layerCanvases})
}


const previousHighlights = {}
function highlight_trajectory(traj_index, layer_index, options){
  // $optics1-completed-color: blue;
  // $optics2-completed-color: red;

  options = options || {}
  // console.log({layerTrajectories, layerCanvases})
  layer_index = layer_index ? layer_index : Object.keys(layerTrajectories)[0]
  
  // clear previous highlight
  var previousHighlight = previousHighlights[layer_index] || {}
  if(Object.keys(previousHighlight).length > 0){
    var traj_indexes = Object.keys(previousHighlight)

    traj_indexes.forEach( traj_index => {
      previousHighlight[traj_index].forEach( (attrs, index) => {
        layerTrajectories[layer_index][traj_index][index].obj.set(attrs)
      })
    })
  }

  previousHighlights[layer_index] = {}
  previousHighlights[layer_index][traj_index] = []

  if(layerTrajectories[layer_index] && layerTrajectories[layer_index][traj_index]){
    layerTrajectories[layer_index][traj_index].forEach( traj_status =>{
      previousHighlights[layer_index][traj_index].push({stroke: traj_status.obj.stroke})
      traj_status.obj.set({
        stroke: traj_status.optics_id === 1 ? 'blue' : 'red',
      })
    })
    layerCanvases[layer_index].renderAll()
  } else if(traj_index > 0) {
    console.warn(`traj or layer not found: traj_index ${traj_index}, layer_index: ${layer_index}`)
  }
}

function highlight_trajectories(filter, layer_index){
  console.log(layerTrajectories)
  const trajs = layerTrajectories[layer_index]
  if(!trajs) {
    console.warn(`layer not found: ${layer_index}`)
    return
  }
  Object.keys(trajs).forEach(traj_index =>{
    trajs[traj_index].forEach(traj_status =>{
      if(filter(traj_index, traj_status.optics_id)){
        traj_status.obj.set({
          stroke: traj_status.optics_id === 1 ? 'blue' : 'red',
        })
      }
    })
  })
  layerCanvases[layer_index].renderAll()
}


function pause_drawing(pause_drawing){
  drawingConfig['pause_drawing'] = !!pause_drawing
}

function update_drawing_speed(speed_factor){
  drawingConfig['speed_factor'] = speed_factor

  pause_drawing(false)
}

function redraw_canvas(){
  const { data } = drawingConfig
  $(data.el_id).html('')

  draw_canvas(data)
}

export default {
  layerCanvases, layerTrajectories,
  render_canvas, draw_canvas, pause_drawing, update_drawing_speed, redraw_canvas,
  highlight_trajectory, highlight_trajectories,
}
