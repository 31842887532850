function is_visible(el) {
  if (typeof jQuery === "function" && el instanceof jQuery) {
      el = el[0];
  }
  var rect = el.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const plotly_options = {responsive: true, displayModeBar: true}
const defaul_layout_xaxis_props = {
  zeroline: true,
  automargin: true,
  autorange: false,
  zerolinecolor: '#444',
  showspikes: false,
  spikemode: "across",
  spikedash: "solid",
  spikecolor: "#f00",
  spikethickness: 1,
  spikesnap: "cursor"
}
function chart_layout(options) {
  const {chart_data, layout_xaxis_props, layout_yaxis_props, annotations} = options
  // console.log(options)
  return {
    // shapes: layout_shapes,
    annotations: annotations,
    spikedistance: 200,
    hoverdistance: 10,
    hovermode: 'x',
    xaxis: {
      ...defaul_layout_xaxis_props, ...layout_xaxis_props,
      title: chart_data.name,
    },
    yaxis: {
      ...layout_yaxis_props
    }
  }
}
const spinner = name => `<div class="d-flex align-items-center m-5">
  <strong>Loading ${name}...</strong>
  <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
</div>`
const hover_handler = (data) => {
  var pt = data.points[0]
  var index = 0
  document.querySelectorAll(".time-series-chart").forEach( _chart_el => {
    if(!_chart_el.querySelector('.plot-container')) return

    const hover = () => {
      try {
        Plotly.Fx.hover(_chart_el, {xval: data.xvals[0]} )
        Plotly.relayout(_chart_el, {
          shapes: [{
            type: 'rect',
            xref: 'x', yref: 'paper',
            x0: pt.x, y0: 0,
            x1: pt.x, y1: 1,
            line: { color: '#f00', width: 1 }
          }]
        })
      } catch (e) {
        // error when the chart is not loaded
        console.log(e)
      }
    }
    if(is_visible(_chart_el)){
      hover()
    } else {
      setTimeout( () => hover(), 20 + index % 10)
      index += 1
    }
  })
}

function fetch_charts(chart_elements, options) {
  let {retried_times} = options
  retried_times += 1
  const url = new URL(location.href);
  url.searchParams.delete("chart_keys[]")
  chart_elements.forEach( chart_element => {
    const {chart_key, chart_element_id, chart_cat} = chart_element
    const _chart_el = document.getElementById(chart_element_id)
    if(_chart_el){
      _chart_el.innerHTML = spinner(chart_key)
      url.searchParams.append('chart_key', chart_key);
    }
  })

  // console.log(url.toString())
  $.ajax({
    url: url.toString(),
    dataType: "json"
  }).done(response => {
    var chart_datas = response.chart_datas
    var layout_xaxis_props = response.layout_xaxis_props
    var layout_yaxis_props = response.layout_yaxis_props

    chart_elements.forEach(chart_element => {
      const {chart_key, chart_element_id} = chart_element
      // console.log({chart_key, chart_element_id, chart_data})
      const _chart_el = document.getElementById(chart_element_id)
      
      if(!_chart_el) return
      
      if (chart_datas == null) {
        _chart_el.innerHTML = `<div style="text-align: center; padding-top: 20px; padding-bottom: 20px"><i class="fa-solid fa-chart-line" style="font-size: 2rem"></i><br /><small>No time series data for ${response.name}</small></div>`
        return
      }

      const chart_data = chart_datas[chart_key]
      
      _chart_el.innerHTML = ""
      drawChart(
        _chart_el, [chart_data],
        chart_layout({chart_data, layout_xaxis_props, layout_yaxis_props}),
        plotly_options
      );
      _chart_el.on('plotly_hover', hover_handler)
    })
  }).fail((e) => {
    if (retried_times < 3) {
      console.log("retry ----------", retried_times)
      fetch_charts(chart_elements, {...options, retried_times: retried_times})
    } else {
      throw ("Error when fetch " + url + e);
    }
  })
}


export default {
  fetch_charts
}
