// Build a worker from an anonymous function body
// ---------- Blob start ---------------
var blobURL = URL.createObjectURL( new Blob([ '(',
function(){
  // Web Worker can not access dom, here is a minium DOM api implementation that we need
  function Element(name, opt) {
    opt = opt || {}
    var self = this
    this.tagName = name;
    this.children = opt['children'] || [];
    this.attrs = opt['attrs'] || {}
    // this.classList = []

    this.cloneNode = function () {
      return new Element(
        self.tagName,
        {
          'children': self.children.map(x => x.cloneNode()),
          'attrs': Object.assign({}, self.attrs)
        }
      )
    }

    this.appendChild = function (child) {
      self.children.push(child)
    }
    this.setAttribute = function (attr, val) {
      self.attrs[attr] = val
    }

    this._propertify = function () {
      var attrs_str = Object.keys(self.attrs).map(function (attr) {
        return `${attr}="${self.attrs[attr]}"`
      }).join(' ')
      // attrs_str += ` class="${self.classList.join(' ')}"`
      return attrs_str
    }
  }
  Element.prototype.__defineGetter__('innerHTML', function () {
    return this.children.map(x => x.outerHTML).join("\n")
  })
  Element.prototype.__defineGetter__('outerHTML', function () {
    return `<${this.tagName} ${this._propertify()}> ${this.innerHTML} </${this.tagName}>`
  })


  onmessage = function (event) {
    // var workerResult = event.data;
    var svg_json = event.data.svg_json || {}

    svg_json.stroke_width_styles.forEach(function (stroke_width_type, stroke_width_type_index) {
      var svg_groups = []
      var i = 0 //stroke_width loop index

      // refer fleet_core/app/models/concerns/layer_to_svg.rb
      svg_json.trajectories.forEach((traj) => {
        var svg_g = new Element('g')
        var optics_class = `optics-${traj.optics_id}`
        svg_g.setAttribute("class", stroke_width_type_index === 0 ? `traj_${traj.index} ${optics_class}` : `${optics_class}`)
        svg_g.setAttribute("data-index", traj.index)
        var geometry_code = traj.geometry_code
        var scanning_rule = svg_json.scanning_rules[traj.scanning_rule_index]
        if(!scanning_rule) throw("Scanning rule not found")
        var beam_diameter = scanning_rule['D033']

        // console.log({scanning_rule})
        var r = 1
        var stroke_width = 1

        var klass = null
        if (scanning_rule) {
          if (stroke_width_type === 'diameter') {
            stroke_width = beam_diameter
            r = stroke_width * 2
            klass = 'diameter_stroke'
          } else if (stroke_width_type === 'melt_pool') {
            stroke_width = beam_diameter * 2.0
            r = stroke_width * 2
            klass = 'melt_pool_stroke'
          } else {
            stroke_width = beam_diameter * 0.1
            klass = 'thin_line_stroke'
          }
        } else {
          klass = 'no_scanning_rule_stroke'
        }

        stroke_width = Math.abs(stroke_width)
        // # typeTrajectory: 0 polyline, 1 points, 2 stroke 线段
        if (geometry_code === 0) {
          // var prev_pt = [null, null]
          // console.log("traj['points'].", traj['points'])
          var polyline = new Element('polyline')
          // polyline.setAttribute('shape-rendering', 'optimizeSpeed')
          polyline.setAttribute('class', `contour_stroke ${klass}`);
          polyline.setAttribute('fill', `none`);
          polyline.setAttribute('style', (stroke_width ? `stroke-width: ${stroke_width}` : ''))
          var points = traj['points'].map( p => `${p.join(',')}` ).join(' ')
          // console.log({points})
          polyline.setAttribute('points', points)
          svg_g.appendChild(polyline)
        } else if (geometry_code === 1) {
          var _newCircle = new Element('circle')
          _newCircle.setAttribute("r", r)
          _newCircle.setAttribute("fill", '#00f')
          // _newCircle.setAttribute('shape-rendering', 'optimizeSpeed')

          traj['points'].forEach((pt) => {
            var x = pt[0]
            var y = pt[1]
            var newCircle = _newCircle.cloneNode()
            newCircle.setAttribute("cx", x)
            newCircle.setAttribute("cy", y)

            svg_g.appendChild(newCircle)
            // svg_trajs.push(newCircle)
          })
        } else if (geometry_code === 2) {
          var i, j, temparray, chunk = 2;
          var path = new Element('path')
          // path.setAttribute('shape-rendering', 'optimizeSpeed')
          path.setAttribute('class', `filling_stroke ${klass}`);
          path.setAttribute('style', (stroke_width ? `stroke-width: ${stroke_width}` : ''))
          var path_d = ""
          for (i = 0, j = traj["points"].length; i < j; i += chunk) {
            temparray = traj["points"].slice(i, i + chunk);
            var pt1 = temparray[0].map(x => x.toFixed(5) )
            var pt2 = temparray[1].map(x => x.toFixed(5) )
            if (pt2) {
              path_d += ` M${pt1.join(' ')} L${pt2.join(' ')}`
            } else {
              console.warn(`Warning: ignoring pt1 ${pt1} because missing pt2`)
            }
          }
          // console.log(path_d)
          path.setAttribute('d', path_d)
          svg_g.appendChild(path)
        } else {
          throw `Unknown geometry_code: ${geometry_code}`
        }
        svg_groups.push(svg_g)
      }) // end trajectories forEach

      postMessage({ 
        append_el_id: `${event.data.el_id} .group-${stroke_width_type} ${event.data.page_group}`, 
        el_id: event.data.el_id,
        layer_index: svg_json.layer_index,
        html: svg_groups.map(x => x.outerHTML ).join('')
      });
    })
  };
}.toString(),
')()' ], { type: 'application/javascript' } ) )
// ---------- Blob end ---------------

/* Init Workers */
var workerPool = [];
var threadCount = navigator.hardwareConcurrency || 2;
for (var i = 0; i < threadCount; i++) {
  workerPool.push({
    worker: new Worker( blobURL ),
    busy: false,
    group_id: null
  })
}
URL.revokeObjectURL( blobURL );

/* Listen for Worker */
workerPool.forEach(function (workerThread) {
  workerThread.worker.addEventListener('message', function (e) {
    // console.debug("received from worker", e.data)
    workerThread.busy = false
    // console.log("append", e.data)
    if (e.data.el_id && e.data.html && $(`${e.data.el_id} svg`).attr('data-layer-index') === `${e.data.layer_index}`  ) {
      // console.debug(e.data.el_id, document.body.querySelector(e.data.el_id))
      var append_element = document.body.querySelector(e.data.append_el_id)
      if (append_element) {
        append_element.insertAdjacentHTML('beforeend', e.data.html)
        $(e.data.append_el_id).trigger("svg_render:updated")
      }else {
        console.warn(`Warning: '${e.data.el_id}' not found(this could be expected, as layer switched)`)
      }
    }
  });
})

/*
How svg rendered

1. Create base SVG element from svg_data(identified by layer id)
2. Create SVG group placeholder by pages
3. Fetch trajectories data from server, pass data to worker
4. Workers create SVG elements and append to SVG group element(by postMessage)

*/
export default function render_svg(data) {
  var has_next_page = true
  var page_index = 1

  var svg_json = data.svg_json
  var ns = 'http://www.w3.org/2000/svg'
  var svg = $(`${data.el_id} svg`)[0] || document.createElementNS(ns, 'svg')

  svg.setAttribute('width', '100%')
  svg.setAttribute('height', '100%')
  svg.setAttribute("data-transform-matrix", svg_json.transform_matrix)
  svg.setAttribute("data-layer-index", svg_json.layer_index)
  svg.setAttribute("data-recipe-id", svg_json.print_recipe_id)
  svg.setAttribute('viewBox', svg_json.viewBox)
  svg.setAttribute('style', svg_json.style)

  var stroke_width_styles = svg_json.stroke_width_styles

  // console.log({stroke_width_styles})
  svg.replaceChildren() // clear
  stroke_width_styles.forEach((stroke_width_style) => {
    var group = document.createElementNS(ns, 'g')
    group.setAttribute("class", `stroke-group group-${stroke_width_style}`)
    group.setAttribute("style", `transform: matrix(${svg_json.transform_matrix.split(' ').join(',')})`)

    svg.appendChild(group)
  })
  // document.body.querySelector(data.el_id).innerHTML = svg.outerHTML
  $(data.el_id).append(svg)

  // split trajectory_data into chunk
  var cycle_size = workerPool.length
  // console.debug('data', data)
  const fetchPagesLoop = async _ => {
    // if render data updated, drop current process
    var layer_index_not_changed = $(`${data.el_id} svg`).attr('data-layer-index') === `${data.svg_json.layer_index}`
    while (layer_index_not_changed && has_next_page && page_index <= svg_json.page_count) {
      // var next_page_path = data.base_url + data.trajectories_data_path + "&page=" + page_index
      // console.debug(`has_next_page--- ${page_index}`)
      var next_page_paths = []

      // page_i used for control batch request size
      for (var page_i = 0; page_index <= svg_json.page_count && page_i < 10; page_i++, page_index++) {
        // console.debug(`page--- ${page_index}`)
        var page_group = document.createElementNS(ns, 'g')
        page_group.setAttribute("class", `page-${page_index}`)

        // create placeholder, so we known where to append the result
        var stroke_groups = document.body.querySelectorAll(`${data.el_id} svg .stroke-group`)
        for (var i = 0, len = stroke_groups.length; i < len; i++) {
          stroke_groups[i].appendChild(page_group.cloneNode())
        }
        next_page_paths.push(data.base_url + data.trajectories_data_path + "&page=" + page_index)
        if (page_index >= svg_json.page_count) {
          has_next_page = false
        }
      }

      let responses = await Promise.all(next_page_paths.map(x => fetch(x).then(resp => resp.json())))
      var jobIndex = 0

      responses.forEach(function (response_json) {
        var trajectories = response_json.trajectories;
        // console.debug('response', response_json, trajectories[])
        if (trajectories && trajectories.length > 0) {
          var worker_data = Object.assign(data, {
            index: jobIndex,
            workerIndex: jobIndex % cycle_size,
            page_group: `.page-${response_json.current_page}`,
            svg_json: Object.assign(svg_json, { trajectories: trajectories })
          })
          jobIndex += 1
          // console.debug(worker_data)
          delete worker_data.callback
          workerPool[jobIndex % cycle_size].worker.postMessage(worker_data)
        } else if (trajectories && trajectories.length === 0) {
          has_next_page = false
        }
      })
    }
    // console.log(data)
    if($(`${data.el_id} svg`).attr('data-layer-index') === `${data.svg_json.layer_index}`){
      data.callback && data.callback()
    }
  }
  fetchPagesLoop()
}