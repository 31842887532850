// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap"
import 'select2'
import flatpickr from "flatpickr";

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

//require("../../assets/javascripts/vendor/svg-pan-zoom.js")

import svgPanZoom from 'svg-pan-zoom'
window.svgPanZoom = svgPanZoom;

import * as d3 from 'd3-zoom'
window.d3 = d3;

import render_svg from "src/render_svg"
window.render_svg = render_svg;

import canvas_tools from "src/canvas_tools"
window.canvas_tools = canvas_tools;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'src/table_sort'
import 'src/utils'
import time_series from '../time_series'
window.time_series = time_series


$(function() {
  $('.flatpickr').each(function() {
    $(this).flatpickr({
      wrap: true
    });
  })

  $('[data-datetime-picker]').each(function() {
    var value = this.value;
    $(this).flatpickr({
      defaultDate: ($(this).data('default-time') || null),
      enableTime: true,
      time_24hr: true
    });

    // Set the value back for placeholder
    if (value == '') {
      $(this).val('')
    }
  })

  $('.select2-enable').select2({
    theme: 'bootstrap4',
    dropdownAutoWidth: true,
    matcher: function(params, data) {
      var original_matcher = $.fn.select2.defaults.defaults.matcher;
      var result = original_matcher(params, data);
      if (result && data.children && result.children && data.children.length != result.children.length) {
        result.children = data.children;
      }
      return result;
    }
  });

  $(document).on('click', '.clickable[data-url]', function(e) {
    if (e.target.tagName == 'A' || e.target.tagName == 'BUTTON' || $(e.target).parents('a, button').length) {
      return
    }
    e.preventDefault();
    var url = $(e.target).parents('tr').data('url')
    if (window.event.ctrlKey || window.event.metaKey){
      window.open(url, '_blank')
    } else {
      location.href = url;
    }
  })

})

$(function() {
  $('[data-toggle-rule-summary]').popover({
    //trigger: 'hover',
    html: true,
    //content: function() {
      //return $(this).data('custom-content')
    //}
  });

  $('[data-toggle="tooltip"]').tooltip();
})

function formatCoordinate(x) {
  return (x * 1000).toFixed(3) + "mm"
}

function initTrCollapse() {
  $(document).on('click', '[data-collapse-trigger]', function(event) {
    event.preventDefault();
    var $tr = $(this).parents('tr').first()
    var $content = $tr.next('tr[data-collapse-content]');

    let remoteUrl = $content.data('remote-content')

    let collapse = () => {
      if ($content.is(':visible')) {
        $tr.find('> td > [data-collapse-trigger]').removeClass('open');
        $content.find('> td > div[data-collapse-content-container]').slideUp(function() {$content.hide()});
      } else {
        $tr.find('> td > [data-collapse-trigger]').addClass('open');
        $content.show();
        $content.find('> td > div[data-collapse-content-container]').slideDown({
          start: function() {
            $(document).trigger('collapse-on-start', [event, $content.find('> td > div[data-collapse-content-container]')]);
          }
        });
      }
    }

    if (remoteUrl && !$content.data('remote-loaded')) {
      var $spinner = $('<i class="fas fa-spinner fa-spin"></i>')
      $tr.find('> td > [data-collapse-trigger]').addClass('loading');
      // $tr.find('span.spinner').append($spinner)

      $.ajax({
        url: remoteUrl,
        type: 'GET',
        dataType: 'json',
        success: function (data, textStatus, jqXHR) {
          console.log("collapseed ...")
          $content.find('> td > div[data-collapse-content-container]').html(data.result)
          $content.data('remote-loaded', true)
          collapse()
        },
        error: function (jqXHR, textStatus, errorThrown) {
          alert('Unable to fetch trajectories data. Please refresh page and try again.')
        },
        complete: function () {
          $tr.find('> td > [data-collapse-trigger]').removeClass('loading');
          // $spinner.remove()
        }
      });
    } else {
      collapse()
    }
  });
}

function activeTab(id){
  if($('.nav-tabs a.active').length > 0) return;

  id = id || window.location.hash
  if(id && $(`.nav-tabs a:[href="${id}"]`).length > 0){
    $(`.nav-tabs a:[href="${id}"]`).tab('show')
  }else {
    $('.nav-tabs a:first').tab('show')
  }
}

$(function() {
  initTrCollapse()
  $("[data-toggle='collapse'][aria-expanded='true']").click()
  activeTab()

 $(document).on('mousemove mouseover mouseleave', '.svg_preview svg', function(event) {
  // $(document).on('mousedown', '.svg_preview svg', function(event) {
    let distance_unit = $(event.target).parents('.svg_preview svg').addBack().data('distance-unit')
    let mouseIn = event.type === 'mouseover' || event.type == 'mousemove'
    if (mouseIn) {
      // Create an SVGPoint for future math
      var pt = this.createSVGPoint();

      // Get point in global SVG space
      pt.x = event.clientX
      pt.y = event.clientY;

      var transform_matrix = $(this).data('transform-matrix').split(" ")
      let containerEl = this
      // Handle svg-pan-zoom matrix
      if($(this).find("> .svg-pan-zoom_viewport").length > 0){
        containerEl = $(this).find("> .svg-pan-zoom_viewport")[0]
      }
      var m = containerEl.getScreenCTM()

      m.a = transform_matrix[0]
      m.b = transform_matrix[1]
      m.c = transform_matrix[2]
      m.d = transform_matrix[3]
      m.e = transform_matrix[4]
      m.f = transform_matrix[5]

      var loc = pt.matrixTransform(containerEl.getScreenCTM().inverse()).matrixTransform(m.inverse());
      let x = loc.x.toFixed(6), y = loc.y.toFixed(6)

      let $text = $('#svg_hover_text')
      if ($text.length == 0) {
        $('body').append('<small id="svg_hover_text"/>')
        $text = $(this).find('#svg_hover_text')
      }

      $text.css({ 'top': event.clientY + 10, left: event.clientX + 10 })

      $text.html(`Current Coordinates: <br/> (${formatCoordinate(x)}, ${formatCoordinate(y)})`)
      $text.show()
    } else {
      $('#svg_hover_text').hide()
    }
  })

  $(document).on('mouseover mouseout', '[data-matched-traj]', function(event) {
    let id = $(this).data('matched-traj')
    let splits = id.split('_')
    let traj_index = parseInt(splits[splits.length - 1])

    let layer_index = parseInt($(this).closest('tr[data-remote-content]').find('.svg_preview').data('layer-index'))

    let mouseIn = event.type === 'mouseover'
    if(mouseIn) {
      canvas_tools.highlight_trajectory(traj_index, layer_index)
    } else {
      canvas_tools.highlight_trajectory(-1, layer_index)
    }

    // let $svg = $(this).closest('tr[data-remote-content]').find(id)
    // let mouseIn = event.type === 'mouseover'

    // if ($svg.data('origin-index') == undefined) {
    //   $svg.data('origin-index', $svg.index())
    // }

    // $svg.toggleClass('highlight-svg', mouseIn)

    // if (mouseIn) {
    //   $svg.appendTo($svg.parent())
    // } else {
    //   let index = $svg.data('origin-index')
    //   if (index == 0) {
    //     $svg.prependTo($svg.parent())
    //   } else {
    //     $svg.insertAfter($svg.parent().children().eq(index - 1))
    //   }
    // }
  })

  $(document).on('click', '[data-edit-comment]', function(event) {
    event.stopPropagation()
    let id = $(this).data('edit-comment')
    let comment = $(this).data('comment')
    let name = $(this).data('name')

    let $modal = $('[data-comment-modal]')
    $modal.find('input[name="id"]').val(id)
    $modal.find('textarea[name="comment"]').val(comment)
    $modal.find('.modal-title').html(`Edit comment of ${name}`)
    $modal.modal('show')
  })

  $(document).on('click', '.printers-list .printers-list-item', function(e) {
    location.href = $(e.target).parents('.printers-list-item').addBack().data('url')
  })

  $(document).on('change', 'select#sort_by', function(e) {
    location.href = '?sort_by=' + $(e.target).val();
  })
});

let loadLatestStatusTimeoutPointer = {}

document.addEventListener("DOMContentLoaded", function() {
// document.addEventListener("turbolinks:load", function() {
  Object.values(loadLatestStatusTimeoutPointer).forEach(function(pointer) {
    clearTimeout(pointer)
  })

  $('[data-toggle="popover"]').popover()

  $('.print-log-data-expand').on('click', function(evet) {
    $(event.target).parents('td').find('.print-log-data-expand').hide()
    $(event.target).parents('td').find('.print-log-data-detail').show()
  })

  $('.print-log-data-collapse').on('click', function(evet) {
    $(event.target).parents('td').find('.print-log-data-detail').hide()
    $(event.target).parents('td').find('.print-log-data-expand').show()
  })

  function loadLatestStatus(id) {
    let load = () => {
      $.ajax({
        url: window.PrinterStatusUrl,
        type: 'get',
        dataType: 'script',
        data: { id: id },
        complete: function () { // Always refresh
          loadLatestStatusTimeoutPointer[id] = setTimeout(load, 5 * 1000)
        }
      });
    }

    loadLatestStatusTimeoutPointer[id] = setTimeout(load, 5 * 1000)
  }

  $('[data-printer-status]').each(function(index) {
    let $printer = $(this)
    let id = $printer.data('printer-status')

    loadLatestStatusTimeoutPointer[id] = setTimeout(function() {
      loadLatestStatus(id)
    }, 10 * 1000 + index * 1000)
  })

  $('[data-table-sorting]').tableSort();
})
